import { userLogoutAction } from "../actions/userActions";
import { removeToken } from "../../services/auth/AuthToken";
import { isEmpty, isNumber } from "lodash";
import { toast } from "react-toastify";
import { COMPANY_CHECKING } from "../types";

const companyMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  if (action.type === COMPANY_CHECKING) {
    const state = getState();
    const COMPANY_ID = state.userReducer.user?.company_id;
    const DEPARTMENT = state.userReducer.user?.department;
    
    // Checking if user already logged in
    if(!isEmpty(state.userReducer.user)){
      // Checking if user has number as company id & empty department & company id will redirect to Login Page
      if (isNumber(COMPANY_ID) || isEmpty(COMPANY_ID) || isEmpty(DEPARTMENT)) {
        dispatch(userLogoutAction());
        removeToken();
        toast.error("Unknown error occured. Please Contact Admin. Redirecting to Login...")
        action.navigate("/login");
      }
    }
  }

  return next(action);
};

export default companyMiddleware;