export function ellipsisMiddle(str) {
  if (str.length > 35) {
    return str.substr(0, 15) + "..." + str.substr(str.length - 10, str.length);
  }
  return str;
}

export function ellipsisString(str, num) {
  if (str.length > num) {
    return str.substr(0, num) + "...";
  }
  return str;
}

export function sanitizeObj(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));
}

export function checkValidUrl(urlString) {
  var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}

export function removeUnderscoreEachKeyObject(obj) {
  const result = {};
  Object.keys(obj).forEach(function (key) {
    result[key.replace(/_/g, ' ')] = obj[key];
  });

  return result;
}

export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export function checkingPermissions(arrPermissions, code) {
  // Checkin codename permission if exist on permissions will return boolean
  return arrPermissions.some(e => e.codename === code.toLowerCase())
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function formatMoney(string) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  if (string === null) {
    return null;
  } else {
    return formatter.format(string);
  }
}


// This function for filtering array of object to single object value
export const getValuesFromObject = ({ value, arrList }) => {
  const listData = arrList ?? []
  const filteredValues = listData?.find((e) => {
    return e?.value === value
  })
  return filteredValues
}

export function calculateBusinessDays(startDate, endDate) {
  // Validate input
  if (endDate < startDate)
    return 0;

  // Calculate days between dates
  var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1);  // Start just after midnight
  endDate.setHours(23, 59, 59, 999);  // End just before midnight
  var diff = endDate - startDate;  // Milliseconds between datetime objects    
  var days = Math.ceil(diff / millisecondsPerDay);

  // Subtract two weekend days for every week in between
  var weeks = Math.floor(days / 7);
  days = days - (weeks * 2);

  // Handle special cases
  var startDay = startDate.getDay();
  var endDay = endDate.getDay();

  // Remove weekend not previously removed.   
  if (startDay - endDay > 1)
    days = days - 2;

  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay == 0 && endDay != 6) {
    days = days - 1;
  }

  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay == 6 && startDay != 0) {
    days = days - 1;
  }

  return days;
}

export const generateMinDateToCreateMaterial = () => {
  let minDeliveryDate = new Date();
  // minDeliveryDate.setDate(minDeliveryDate.getDate() - 1);
  // minDeliveryDate = new Date(minDeliveryDate.getFullYear(), minDeliveryDate.getMonth() + 1, 1)

  if (minDeliveryDate.getDay() === 0) {
    minDeliveryDate.setDate(minDeliveryDate.getDate() + 3);
  } else if (minDeliveryDate.getDay() === 6) {
    minDeliveryDate.setDate(minDeliveryDate.getDate() + 3);
  } else if (minDeliveryDate.getDay() === 4 || minDeliveryDate.getDay() === 5) {
    minDeliveryDate.setDate(minDeliveryDate.getDate() + 5);
  } else {
    minDeliveryDate.setDate(minDeliveryDate.getDate() + 3);
  }

  return minDeliveryDate;
}

export const generateWeekendDaysOnly = () => {
  const weekends = [];
  const currentYear = new Date().getFullYear();
  const endYear = new Date().getFullYear() + 2;

  const startDate = new Date(currentYear, 0, 1); // January 1st of the given year
  const endDate = new Date(endYear, 11, 31); // December 31st of the given year

  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 6 || dayOfWeek === 0) {
      weekends.push(new Date(date)); // Add a new date object to avoid reference issues
    }
  }

  return weekends;
};

  export function combineTwoPropertiesValueInSameObjectArr(arr) {
    const result = []
    arr.map((obj, i) => (
      result.push(JSON.parse(`{"${obj?.label}":"${obj?.value}"}`))
    ))
    
    return result
  }

export function removePrefixMDM(value){
  if(value.includes('mdm:')){
    return value.replaceAll('mdm:', '');
  }
  return value
}


export function encodeToSymbolic(str) {
  str = (str + '').toString();

  // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
  // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
  return str
    .replace('%21', '!')
    .replace('%28', '(')
    .replace('%29', ')')
    .replace('%2A', '*')
    .replace('%26', '&')
    .replace('%20', ' ')
}

export function generateTotalNumberInReceivingDetailTable(dataArrObjects, totalColumnName) {
  const totalUnits = dataArrObjects?.reduce((acc, curr) => {
    return totalColumnName === "Ordered" ? acc += curr?.quantity : totalColumnName === "Received" ? acc += curr?.ref_quantity_arrived : 0;
}, 0)
  return totalUnits % 1 != 0 ? Math.round(totalUnits*1000)/1000 : totalUnits
}

export function getCompanyNameFromCompanyId(companyId) {
  return (
    companyId === 2 ? 'Water' : 
    companyId === 5 ? 'Electrical' : 
    companyId === 6 ? 'Energy' : 
    companyId === 7 ? 'Mission Critical' : 'Mechanical' 
)
}