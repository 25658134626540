import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import companyMiddleware from "./middleware/companyMiddleware";
import { COMPANY_CHECKING } from "./types";

const persistConfig = {
  key: "rkmi",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(companyMiddleware, thunk));

const persistor = persistStore(store);

persistor.subscribe(() => {
  // Check if rehydration is complete
  if (persistor.getState().bootstrapped) {
    // Dispatch Company Middleware for checking company permission
    store.dispatch({ type: COMPANY_CHECKING, navigate: () => {} });
  }
});

export { store, persistor };
