import { reactLocalStorage } from "reactjs-localstorage";
import { RK_DIVISION_KEY } from "../../constants/userInfo/userBusinessUnit";

const setToken = (token, refreshed) => {
  reactLocalStorage.setObject("rkmi-auth", token);
  reactLocalStorage.setObject("refreshed", refreshed);
  sessionStorage.clear();
};

const getAccessToken = () => {
  const userToken = reactLocalStorage.getObject("rkmi-auth");
  const accessToken = userToken?.access ?? null;
  return accessToken;
};

const getRefreshToken = () => {
  const userToken = reactLocalStorage.getObject("rkmi-auth");
  const refreshToken = userToken?.refresh ?? null;
  return refreshToken;
};
const getRkDivison = () => {
  const rkDivision = localStorage.getItem(RK_DIVISION_KEY);
  if(rkDivision === null) return null;
  return rkDivision;
};

const isRefreshed = () => {
  const refreshed = reactLocalStorage.getObject("refreshed");
  return refreshed;
};

const removeToken = () => {
  return reactLocalStorage.clear();
};

export { setToken, getAccessToken, getRkDivison, getRefreshToken, isRefreshed, removeToken };
