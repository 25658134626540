import React, { Component } from "react";
import { Dropdown} from "react-bootstrap";
import { connect } from "react-redux";
import { userLogoutAction } from "./../store/actions/userActions";
import { Link } from "react-router-dom";
import { withRouter } from "../helper/withRouter";
// import Widgets from "../widgets/Widgets";
import { removeToken } from "./../services/auth/AuthToken";
import RK_LOGO_WHITE from "../assets/images/rk-logo-white.png";
import { LIST_DIVISION, RK_DIVISION_KEY, USER_BUSINESS_UNIT } from "../constants/userInfo/userBusinessUnit";
import { capitalize, isEmpty } from "lodash";


class Navbar extends Component {
  constructor(props) {
    super(props);

    this.logoNavbar = RK_LOGO_WHITE;
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.location?.pathname !== this.props?.location?.pathname) {
      if (this.props?.location?.pathname?.includes("/materialrecords")) {
        document.body.classList.add("sidebar-icon-only");
      } else {
        document.body.classList.remove("sidebar-icon-only");
      }
    }

     // Periksa apakah state redux yang diinginkan berubah
     if (this.props.someReduxState !== prevProps.someReduxState) {
      this.handleReduxStateChange();
    }
  }
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  handleChangeDivision(division) {
    const rkDivision = division?.toString();
    localStorage.setItem(RK_DIVISION_KEY, rkDivision);

    switch(rkDivision){
      case '6': 
        window.location.replace("/receiving/app");
        break;
      case '1': 
        window.location.replace("/batch-tracker/app");
        break;
      default: 
        window.location.replace("/supply-chain-catalog/requisition-list/material-requisition");
        break;
    }
  }

  handleLogout = async () => {
    const { navigate, userLogoutAction } = this.props;
    await userLogoutAction();
    localStorage.removeItem(RK_DIVISION_KEY);
    removeToken();

    navigate("/login");
  };

  render() {
    const { navigate, userData } = this.props;
    // For Getting Current Department From User
    const USER_DEPARTMENT_LIST = userData?.user?.company_id?.split(',');
    const DEPARTMENT_LIST = USER_DEPARTMENT_LIST?.map(userDep => ({
      id: userDep,
      label: LIST_DIVISION?.find((dep) => dep.id?.toString() === userDep)?.label,
    }))

    const ACTIVE_DEPARTMENT_LIST = DEPARTMENT_LIST?.sort((a, b) => {
      if (a?.label < b?.label) return -1;
      if (a?.label > b?.label) return 1;
      return 0; 
    });

    const isShowingDropdown = ACTIVE_DEPARTMENT_LIST?.length > 1;

    const fullName = `${userData?.user?.first_name} ${userData?.user?.last_name}`;
    const MECHANICAL = 'mechanical';
    const CORPORATE = 'corporate';
    const getFeatures = process.env.REACT_APP_FEATURES.split(", ");
    
    let rkDivision = localStorage.getItem(RK_DIVISION_KEY);

    const resultJobDivision = LIST_DIVISION?.find(val => val?.id?.toString() === rkDivision)?.label;

    // finding business unit label
    let businessUnit = !isEmpty(resultJobDivision) ? resultJobDivision : userData?.user?.department?.toLowerCase() ?? MECHANICAL;

    if (businessUnit?.includes(CORPORATE)) {
      businessUnit = MECHANICAL;
    }

    // finding id for business unit
    const jobDivisionId = USER_BUSINESS_UNIT[businessUnit] || USER_BUSINESS_UNIT[MECHANICAL];
      
    const selectedDivision = !isEmpty(rkDivision)? rkDivision : jobDivisionId
    
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link
            className="navbar-brand brand-logo"
            to="/dashboard"
            style={{
              // display: "flex",
              alignItems: "center",
              justifyContent: "center",
              Width: "100%",
              margin: "0 !important",
            }}
          >
            <img
              src={this.logoNavbar}
              alt="logo"
            />
            {/* <p
              style={{ textAlign: "center", color: "white", fontWeight: "500" }}
            >
              Connect
            </p> */}
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="/">
            <img
              src={this.logoNavbar}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-end">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <span className="ti-layout-grid2"></span>
          </button>
          <ul className="navbar-nav mr-lg-2">
            <li className="nav-item nav-search d-none d-lg-block">
              {
                getFeatures.includes('Help') && (<Link to="/contact-us">
                  <span className="btn" style={{
                    fontWeight: '600',
                    backgroundColor: '#282f3a',
                    color: 'white',
                    textDecoration: 'none',
                  }}>Help</span>
                </Link>)
              }

              {/* <div className="input-group">
                <div
                  className="input-group-prepend hover-cursor"
                  id="navbar-search-icon"
                >
                  <span className="input-group-text" id="search">
                    <i className="ti-search"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="navbar-search-input"
                  placeholder="Search now"
                  aria-label="search"
                  aria-describedby="search"
                ></input>
              </div> */}
            </li>
          </ul>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item mr-2" style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <span style={{
                marginLeft: 'auto'
              }}>{fullName}</span>
              {!isShowingDropdown ? (
                  <span style={{
                    margin: 0,
                    marginLeft: 'auto',
                    fontWeight: 400,
                    fontSize: '13px',
                    color: '#9b9b9b'
                  }}>{capitalize(businessUnit)}</span>
              ) : (
                <Dropdown>
                <Dropdown.Toggle variant="success" className="dropdown-user-division ml-auto  ">
                  <span style={{
                    color: '#9b9b9b',
                    fontWeight: 400,
                    fontSize: '13px'
                  }}>{capitalize(businessUnit)}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu >
                  {
                    ACTIVE_DEPARTMENT_LIST?.map(division => (
                      <Dropdown.Item 
                          active={division?.id?.toString() === selectedDivision?.toString()}
                          onClick={() => this.handleChangeDivision(division?.id)}>{division?.label}
                      </Dropdown.Item>))
                  }
                </Dropdown.Menu>
              </Dropdown>
              )}
           
            </li>
            {/* <li className="nav-item mr-1">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <i className="ti-email"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <p className="p-3 mb-0 text-black">Messages</p>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            {/* <li className="nav-item">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <i className="ti-bell"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                  <p className="p-3 mb-0 text-black">Notifications</p>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            <li className="nav-item nav-profile">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <img
                    style={{ width: "40px", height: "40px" }}
                    src={require("./../assets/images/profile-blank.png")}
                    alt="profile"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={() => navigate("/settings")}
                  >
                    <div className="d-flex align-items-center">
                      <i className="ti-settings text-primary"></i>
                      <span className="pl-2">Settings</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={this.handleLogout}
                  >
                    <div className="d-flex align-items-center">
                      <i className="ti-power-off text-primary"></i>
                      <span className="pl-2">
                        {/* <Link to="/login" onClick={() => removeToken()}>
                          Logout
                        </Link> */}
                        Logout
                      </span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="ti-layout-grid2"></span>
          </button>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer,
    userDataDivision: state.userDivisionReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogoutAction: () => dispatch(userLogoutAction())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
